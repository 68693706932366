import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaRegUser } from "react-icons/fa";
const popins = Poppins({ weight: "400", subsets: ["latin"],display:'swap'});
const quickSand = Quicksand({ weight: "400", subsets: ["latin"],display:'swap'});
const extrapopins = Poppins({ weight: "500", subsets: ["latin"],display:'swap'});



export default function TestimonialSlide({}) {

  const TestiData =[  
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed AWS course.I learn lot of new knowledge not only for AWS but also other from Ghani Sir.Thank for excellent training given by you",
      UserName: "Jaaeic Sseak",
      Course: "AWS DevOps",
    },
    
    {
      img:"/testimonial-img/alex.webp",
      review:"I have completed my full stack development course in learnsoft.org institute. They are very helpful for us to gain technical knowledge.and they are providing internship it is very helpful to improve our skills",
      UserName: "Mohamed",
      Course: "Full Stack Development",
    },
      
    {
      img:"/testimonial-img/alex.webp",
      review:"I am from pune. On first I was afraid to learn the course in online. later I had a demo session on Azure. From the demo to till my whole course completion I had an amazing experience thanks to ghani",
      UserName: "Nishanthini Praveena",
      Course: "Azure DevOps",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed  flutter course at Learnsoft academy. The training was so good and they taught me well and gave a lot of career guidance, Thank you team.",
      UserName: "Pavi Sparrow",
      Course: "Flutter",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:
        "Overall PP training was good.Trainer is knowledgeable and we now have recorded sessions available too.Trainer has provided some good real time examples related to IT environment which helped me too.",
      UserName: "Bala Ganesh",
      Course: "Power Platform",
    }, 
    {
      img:"/testimonial-img/alex.webp",
      review:
        "Institute environment is very well and Trainer knowledge is excellent and with this course i got useful knowledge.",
      UserName: "Priya",
      Course: "Java",
    },   
]

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  infinite: true,
  slidesToScroll: 1,
  arrows:false,
  customPaging: (i) => (
    <div className="slick-dot" key={i}>
      <style jsx>
        {`
          .slick-dot {
            width: 10px;
            height: 10px;
            background-color: #ffa900;
            border-radius: 50%;
            margin: 1px 5px;
            cursor: pointer;
            display: inline-block;
            margin-bottom:-3px;
          }

          @media only screen and (max-width: 1023px){
            .slick-dot {
              width: 10px;
              height: 10px;
              background-color: #ffa900; /* Default color */
              border-radius: 50%;
              margin: 1px 5px;
              cursor: pointer;
              display: inline-block;
              margin-bottom:30px;
            }
          }
        `}
      </style>
    </div>
  ), 
   
  responsive: [
    {
      breakpoint: 1223,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    
  ]
}; 


  return (
    <>
<section className='testi-bg mt70 mmt40'> 
<div className='container'>

              <h4 className={`fs-33 mfs20 tac ${extrapopins.className}`}>Testimonials</h4>

              <h4 className={`fs-33 mfs-19 tac mt20 ${extrapopins.className}`}>What Our Students Say 
              <br/><span className={`ylw-clr ${extrapopins.className}`}>About Us & Our Services Master Courses</span> </h4>



{/* Box */}
<Slider {...settings}>


 {TestiData.map((items,index)=>{
        return(
          <div className='col mt32' key={index}>
          <div className='df fjse'>
        <div className='testi-box ' >
 <div className='df fjsb'>         
<div className='df fac gap11'>
<div className='img'>
<FaRegUser className='pd8 cw tac' size={26}/>
</div>
<div className=''>
  <p className={`fs-16 mfs-12`}>{items.UserName}</p>
  <p className={`fs-12 dim-clr mt4 mfs-9 mmt2`}>{items.Course}</p>
</div>

</div>
<div className=''>
    <Image src={"/testimonial-img/rating-star.webp"} alt='Rating' width={"88"} height={"16"} loading='lazy'/>
  </div>

</div>
<div className='df fjc pdt6 fdc'>
<p className={`fs-14 mfs-10 hvz ${popins.className}`}>{items.review}</p>


</div>

</div>


</div></div> )
      })}</Slider></div> 
</section>
<style jsx>
{`


    .col {
      width: 100%; // Set the width to 100% for smaller screens
      margin-bottom: 52px;
      margin-left: auto;
      margin-right: auto; // Center the slider on the screen
    }

.testi-bg{

// background-color: #FEEAC3;
width: 100%;
height: 700px;
overflow: hidden;

}
.img{
background: #FFA900;
border-radius: 50px;
}
.testi-box{
    width: 100%;
    height: 222px;
    border-radius: 16px;
  background: linear-gradient(to right, rgba(255, 169, 0, 0.3), rgba(255, 207, 200, 0.5));
    cursor: pointer;
    margin:0px 15px;
    padding:32px 20px 10px 20px;
}

@media only screen and (max-width: 1023px){
    .testi-bg{
        width: 100%;     
        }
        .testi-box{
            width: 280px;
            height: 190.62px;
            border-radius: 12.2px;
              background: linear-gradient(to right, rgba(255, 169, 0, 0.3), rgba(255, 207, 200, 0.5));

        }


}
`}

</style>

</>
  )
}
